import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { ArticleFragment } from 'Typings/generated-gatsby-types'
import { ArticleHeader } from 'Components/Article/ArticleHeader'
import { ArticleImage } from 'Components/Article/ArticleImage'
import Text from 'Components/texts/Text'
import { getShortArticleContent, getArticleLink } from 'Utils/common'
import { fadeUp } from 'Utils/animations'
import Button from 'Components/touchables/Button'
import Touchable from 'Components/touchables/Touchable'
import styles from './ShortArticle.module.scss'

export type ShortArticleProps = {
  className?: string
  article: ArticleFragment
}

export const ShortArticle = (props: ShortArticleProps) => {
  const { className = '', article } = props

  const [t] = useTranslation()

  return (
    <Touchable
      link={getArticleLink(article.slug)}
      className={classNames([styles.root, className])}
    >
      <ArticleHeader article={article} size="small" />
      <ArticleImage
        article={article}
        className={styles.image}
        {...fadeUp({ index: 2 })}
      />
      <Text className={styles.text} variant="sm" {...fadeUp({ index: 3 })}>
        {getShortArticleContent(article)}
      </Text>
      <div {...fadeUp({ index: 4 })}>
        <Button
          title={t('article.read_more')}
          className={styles.button}
          variant="outline"
        />
      </div>
    </Touchable>
  )
}
