import React, { Fragment, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { ArticleFragment } from 'Typings/generated-gatsby-types'
import { Divider } from 'Components/Divider/Divider'
import { ShortArticle } from 'Components/Article/ShortArticle'
import Button from 'Components/touchables/Button'
import { fadeUp } from 'Utils/animations'
import styles from './ArticlesList.module.scss'

const PAGE_SIZE = 10

export type ArticlesListProps = {
  className?: string
  articles: Array<ArticleFragment>
}

export const ArticlesList = (props: ArticlesListProps) => {
  const { className = '', articles } = props

  const [t] = useTranslation()

  const [articlesCount, setArticlesCount] = useState(PAGE_SIZE)

  const articlesIds = useMemo(() => articles.map(({ id }) => id).join('-'), [articles])

  useEffect(() => {
    setArticlesCount(PAGE_SIZE)
  }, [articlesIds])

  const hiddenCount = articles.length - articlesCount

  const onShowMore = () => {
    setArticlesCount((p) => Math.min(p + PAGE_SIZE, articles.length))
  }

  return (
    <>
      <div className={classNames([styles.root, className])}>
        {articles.map((article, index) => (
          <Fragment key={article.id}>
            <ShortArticle
              article={article}
              className={classNames([
                styles.article,
                { [styles.visible]: index < articlesCount },
              ])}
            />
            {index < articlesCount - 1 && <Divider />}
          </Fragment>
        ))}
      </div>
      {hiddenCount > 0 && (
        <>
          <Divider />
          <div {...fadeUp({ index: 1 })}>
            <Button
              title={t('article.show_more', { count: Math.min(hiddenCount, PAGE_SIZE) })}
              className={styles.button}
              onClick={onShowMore}
              variant="flat"
            />
          </div>
        </>
      )}
    </>
  )
}
