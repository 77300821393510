import ArticlesPage from 'Views/articles/ArticlesPage'
import React, { useEffect } from 'react'
import { graphql, PageProps, navigate } from 'gatsby'
import { ArticlesPageQuery } from 'Typings/generated-gatsby-types'
import { PATHS } from 'Utils/routing'

export default function Articles(props: PageProps<ArticlesPageQuery>) {
  const { data, path, pageContext = {} } = props

  const isEn = (pageContext as Record<string, any>).language === 'en'

  useEffect(() => {
    if (isEn) {
      navigate(PATHS.home)
    }
  }, [isEn])

  if (isEn) {
    return null
  }

  return (
    <ArticlesPage
      data={data.strapiArticlesPage}
      articlesData={data?.allStrapiArticle}
      path={path}
    />
  )
}

export const query = graphql`
  query($language: String!) {
    strapiArticlesPage(locale: { eq: $language }) {
      seo {
        meta_title
        meta_description
        meta_image {
          localFile {
            publicURL
          }
        }
      }
      heading
      sub_heading
    }
    allStrapiArticle(filter: { locale: { eq: $language } }) {
      edges {
        node {
          id
          title
          slug
          locale_key
          content
          main_tag
          additional_tags
          created_at
          image {
            localFile {
              publicURL
            }
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
